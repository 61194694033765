<template>
  <div>
    <sideview
      :is-sidebar-active="sidebarActivated"
      :data="billing_info"
      @closeSidebar="toggleDataSidebar"
    />

    <b-modal
      v-model="promo_popup"
      centered
      hide-footer
      title="Promo Code"
    >
      <b-row class="w-100 mx-auto my-1">
        <h6 class="mx-auto">
          Enter your promo code below if you have one
        </h6>
      </b-row>
      <b-row class="w-100 mx-auto my-1">
        <b-form-input
          v-model="promo_code"
          class="sm:w-100 mx-1"
          placeholder="PROMO CODE"
        />
      </b-row>

      <div class="d-flex w-100 mx-auto my-1">
        <b-button
          variant="danger"
          class="vx-col mx-1 mt-1"
          @click="skipPromo"
        >Skip</b-button>
        <b-button
          variant="warning"
          class="vx-col mx-1 mt-1"
          @click="validatePromo"
        >Validate Promo Code</b-button>
      </div>
    </b-modal>

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          xl="4"
        >
          <b-card
            title="Market Presence"
            class="w-100"
            style="height: 31rem;"
          >
            <div v-if="presence_active">
              <div
                v-if="presence_message.length > 1"
                class="card-overlay text-white"
                style="height: 28rem"
              >

                <p
                  style="margin-top: 100px!important;"
                  class="text-white"
                >
                  {{ presence_message }}
                </p>

              </div>
              <div v-else>
                <div
                  v-if="!presence.available"
                  class="card-overlay text-white"
                >
                  <p class="text-white mb-2 tracking-wide">
                    Computing...
                  </p>
                </div>

                <vue-apex-charts
                  type="donut"
                  height="350"
                  :options="presence.chartOptions"
                  :series="presence.series"
                />
              </div>
            </div>
            <div
              v-else
              class="card-overlay text-white mt-4"
              style="height: 27rem"
            >
              <stripe-checkout
                ref="checkoutRefP"
                mode="subscription"
                :pk="publishableKey"
                :client-reference-id="payment_id"
                :customer-email="customer_email"
                :line-items="presenceItems"
                :success-url="successURL"
                :cancel-url="cancelURL"
                @loading="v => (loading = v)"
              />
              <b-row
                class="w-100 mt-6"
                style="margin-top:100px;"
              >
                <p class="p-2 mt-24">
                  Corporate Market Presence plays an important role and is a factor
                  that Job Seekers would consider before submitting their
                  application. As published by LinkedIn, 75% of job seekers consider
                  an employer’s brand before even applying for a job.
                </p>
              </b-row>

              <b-row class="w-100">
                <b-button
                  variant="warning"
                  class="vx-row mx-auto mt-1"
                  @click="subscribe('presence')"
                >Subscribe now for S$75 per month</b-button>
              </b-row>
            </div>

          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="6"
          xl="4"
        >
          <b-card
            title="Market Sentiment"
            style="height: 31rem"
          >
            <div v-if="sentiment_active">
              <div
                v-if="sentiment_message.length > 1"
                class="card-overlay text-white"
                style=""
              >
                <p class="text-white mb-2 tracking-wide mx-auto mt-6">
                  {{ sentiment_message }}
                </p>
              </div>
              <div v-else>
                <div
                  v-if="!sentiment.available"
                  class="card-overlay text-white"
                >
                  <p class="text-white mb-2 tracking-wide">
                    Computing...
                  </p>
                </div>

                <vue-apex-charts
                  type="pie"
                  height="350"
                  :options="sentiment.chartOptions"
                  :series="sentiment.series"
                />
              </div>
            </div>
            <div
              v-else
              class="card-overlay text-white mt-4"
              style="height: 27rem"
            >
              <stripe-checkout
                ref="checkoutRefS"
                mode="subscription"
                :pk="publishableKey"
                :client-reference-id="payment_id"
                :customer-email="customer_email"
                :line-items="sentimentItems"
                :success-url="successURL"
                :cancel-url="cancelURL"
                @loading="v => (loading = v)"
              />

              <b-row
                class="w-100 mt-6"
                style="margin-top:100px;"
              >
                <p class="p-2 mt-6">
                  Market Sentiment plays a crucial role and is a factor that Job
                  Seekers would consider before submitting their application. The
                  Market Sentiment module acts as a Social Listener that allows you
                  to understand how the community speaks about your brand.
                </p>
              </b-row>

              <b-row class="w-100">
                <b-button
                  variant="warning"
                  class="mx-auto mt-1"
                  @click="subscribe('sentiment')"
                >Subscribe now for S$150 per month</b-button>
              </b-row>

            </div>
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="6"
          xl="4"
        >
          <b-card
            height="400"
            title="Coming Soon.."
          >
            <img
              width="500"
              height="350"
              src="https://i.ibb.co/R0F3yv0/coming-soon.jpg"
              alt="content-img"
              class="responsive card-img-top"
            >
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <div class="d-flex justify-content-center mt-6">
      <h4 class="">
        Powered by HeadHunters HQ's Protos Alliance
      </h4>
    </div>
    <hr>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-row
        v-if="active"
        class="vx-row"
      >
        <b-col
          v-for="(item, index) in all_partners"
          :key="index"
          cols="12"
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-card v-if="item.service_type == 'coming-soon'">
            <img
              width="400"
              height="250"
              src="https://i.ibb.co/R0F3yv0/coming-soon.jpg"
              alt="content-img"
              class="responsive card-img-top"
            >
          </b-card>

          <b-card
            v-else
            class="overlay-card overflow-hidden"
          >
            <div>

              <img
                :src="
                  item.image == 'default'
                    ? require('@/assets/images/placeholder/no-image.jpg')
                    : item.image
                "
                alt="content-img"
                class="responsive card-img-top"
                style="height: 250px"
              >
            </div>
            <hr>
            <b-row class="m-1">
              <h4 class="font-weight-bolder">
                Company Name:
              </h4>
            </b-row>
            <b-row class="ml-1">
              <h5 class="">
                {{ item.company_name }}
              </h5>
            </b-row>
            <b-row class="m-1">
              <h4 class="font-weight-bolder">
                Expertise:
              </h4>
            </b-row>
            <b-row class="ml-1 mb-1">
              <h5>
                {{ item.service_type }}
              </h5>
            </b-row>
            <div class="d-flex justify-content-between">
              <b-button
                variant="warning"
                class="mr-1 w-100"
                @click="reachOut"
              >
                Reach out
              </b-button>
            </div>
          </b-card>
          <b-modal
            v-model="reach_popup"
            centered
            hide-footer
            title="Contact Details"
          >
            <div class="d-flex flex-column w-100">
              <div class="d-flex justify-content-between items-center my-1 w-100">
                <p class="font-weight-bolder">
                  Contact Person:
                </p>
                <p>{{ item.fullname }}</p>
              </div>
            </div>

            <div class="d-flex justify-content-center w-100 my-1">
              <div class="vx-col">
                <a
                  class="btn mr-1 btn-warning btn-border w-1/3"
                  :href="`tel:${item.phone_number}`"
                >Contact Via Phone</a>
              </div>

              <div class="vx-col">
                <a
                  class="btn mr-1 btn-warning btn-border w-1/3"
                  :href="`mailto:${item.email}`"
                >Contact Via Email</a>
              </div>
            </div>
          </b-modal>
        </b-col>
      </b-row>

      <div
        v-else
        class="flex justify-center"
      >
        <div class="vx-row w-1/2" />
      </div>
    </b-overlay>
  </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Sideview from '../payments/Sideview.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
    VueApexCharts,
    StripeCheckout,
    Sideview,
  },
  data() {
    return {
      purchase_category: '',
      secondary_code: '',
      promo_code: '',
      promo_popup: false,
      reach_popup: false,
      proceed_transaction: false,
      presence_active: false,
      sentiment_active: false,
      presence_message: '',
      sentiment_message: '',
      customer_email: '',
      active: true,
      sidebarActivated: false,
      billing_info: {},
      gender: {},
      sentiment: {},
      presence: {},
      all_partners: [
        {
          // image: `${this.$bucketUrl}/media/TGS Blue.jpeg`,
          image: `${this.$bucketUrl}/media/TGS Blue.jpeg`,
          company_name: 'THE GAS STATION',
          phone_number: +6997281335,
          email: 'bertrambrandon@thegasstation.sg',
          fullname: 'Bertram Ong',
          service_type: 'Media Marketing and Advertising',
        },
        {
          image: `${this.$bucketUrl}/media/Elliot&Co.png`,
          company_name: 'ELLIOT & CO.',
          phone_number: +6988924822,
          email: 'Laura@elliotcommunications.com',
          fullname: 'Laura Neo',
          service_type: 'Public Relations',
        },
        {
          image: `${this.$bucketUrl}/media/coming-soon.jpg`,
          company_name: 'ELLIOT & CO.',
          phone_number: +6988924822,
          email: 'Laura@elliotcommunications.com',
          fullname: 'Laura Neo',
          service_type: 'coming-soon',
        },
      ],
      themeColors: [
        '#7367F0',
        '#28C76F',
        '#EA5455',
        '#FF9F43',
        '#10163A',
        '#1E1E1E',
      ],
      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      loading: false,
      payment_id: '',
      presenceItems: [
        {
          price: 'price_1Jhi3hI2ri5g5XG91DW2sZpC',
          // price: "price_1Ji0bYI2ri5g5XG9OPVDNRGG",
          quantity: 1,
        },
      ],

      sentimentItems: [
        {
          price: 'price_1Jhi48I2ri5g5XG9EsrFMLps',
          // price: "price_1Ji0flI2ri5g5XG9wnT1MkTf",
          quantity: 1,
        },
      ],
      // https://dashboard.headhuntershq.com
      successURL: 'https://dashboard.headhuntershq.com/payment-success',
      cancelURL: 'https://dashboard.headhuntershq.com/analytics/organization',
      isLoading: true,
    }
  },
  created() {
    this.isLoading = true
    this.customer_email = this.$store.state.auth.ActiveUser.email
    this.getBillingInfo()
    this.generate_payment_id()
    this.get_org_data()
  },
  methods: {

    validatePromo() {
      if (this.promo_code.length > 2) {
        this.$http
          .get(`/api/promo-code/${this.promo_code}/${this.secondary_code}`)
          .then(response => {
            if (response.data.success) {
              this.promo_popup = false
              this.promo_code = ''
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Promo Code Notification',
                    icon: 'AlertCircleIcon',
                    text: 'Your subscription has been activated successfully',
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              window.location.reload()
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Promo Code Notification',
                    icon: 'AlertCircleIcon',
                    text: 'The promo code you have entered is either invalid or has already expired.',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Promo Code Notification',
              icon: 'AlertCircleIcon',
              text: 'A valid promo code is required before validation.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    skipPromo() {
      const section = this.purchase_category
      this.promo_popup = false
      if (section === 'presence') {
        this.$refs.checkoutRefP.redirectToCheckout()
      } else if (section === 'sentiment') {
        this.$refs.checkoutRefS.redirectToCheckout()
      }
    },
    reachOut() {
      this.reach_popup = true
    },
    toggleDataSidebar() {
      this.sidebarActivated = false
    },
    getBillingInfo() {
      this.$http
        .get('/api/billing-info')
        .then(response => {
          if (response.data.success) {
            const results = response.data.output
            this.billing_info = results
            const neededKeys = [
              'phone_number',
              'email',
              'address_1',
              'first_name',
              'last_name',
              'company_name',
            ]

            if (
              neededKeys.every(key => Object.keys(this.billing_info).includes(key))
            ) {
              this.proceed_transaction = true
            } else {
              this.proceed_transaction = false
            }
          }
        })
        .catch(error => {})
    },
    subscribe(section) {
      this.getBillingInfo()
      this.purchase_category = section
      if (section === 'presence') {
        this.secondary_code = 100
        this.successURL = `https://dashboard.headhuntershq.com/payment-success/${this.payment_id}/100`
      } else if (section === 'sentiment') {
        this.secondary_code = 200
        this.successURL = `https://dashboard.headhuntershq.com/payment-success/${this.payment_id}/200`
      }
      if (this.proceed_transaction) {
        this.promo_popup = true
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Billing Notification',
              icon: 'AlertCircleIcon',
              text: 'Please update your billing information before subscribing',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
        this.sidebarActivated = true
      }
    },
    generate_payment_id() {
      this.$http
        .get('/api/payment-id/100')
        .then(response => {
          if (response.data.success) {
            this.payment_id = response.data.payment_id
          }
        })
        .catch(error => {})
    },
    chart_option_generator(chart_type, title, x_data, colors) {
      let chartOptions = {}
      if (chart_type == 'line') {
        chartOptions = {
          chart: {
            background: 'white',
            height: 350,
            zoom: {
              autoScaleYaxis: true,
              enabled: true,
            },
          },
          colors,
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: title,
            align: 'left',
          },
          grid: {
            row: {
              colors: ['transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            type: 'datetime',
            categories: x_data,
          },
        }
      } else if (chart_type == 'pie') {
        chartOptions = {
          labels: x_data,
          colors,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        }
      } else if (chart_type == 'donut') {
        chartOptions = {
          labels: x_data,
          colors,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        }
      } else {
        chartOptions = {
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          colors,
          xaxis: {
            type: 'datetime',
            categories: x_data,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        }
      }
      return chartOptions
    },

    get_org_data() {
      const { token } = this.$store.state.auth.ActiveUser
      const company = this.$store.state.auth.ActiveUser.company_name
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.$http
        .get(`/api/org-analytics/${company}`)
        .then(response => {
          const output = response.data.data
          const market_presence = output.y_market_presence
          const market_sentiment = output.y_sentiment
          const { account_status } = response.data
          this.presence_active = account_status.market_presence.active
          this.presence_message = account_status.market_presence.message
          this.sentiment_active = account_status.market_sentiment.active
          this.sentiment_message = account_status.market_sentiment.message
          // this.all_partners = output["all_partners"];
          this.sentiment = {
            chartOptions: this.chart_option_generator(
              'pie',
              '',
              ['Positive', 'Mixed', 'Negative'],
              this.themeColors,
            ),
            series: market_sentiment,
            available: true,
          }

          this.presence = {
            chartOptions: this.chart_option_generator(
              'donut',
              '',
              ['You', 'Others'],
              this.themeColors,
            ),
            series: market_presence,

            available: true,
          }

          this.isLoading = false
        })
        .catch(error => {})
    },
  },
}
</script>
<style type="scss">
  .card-overlay {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,.60);
      z-index: 0;
  }
</style>
